// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder. 
// Just copy a variable from /sass/bootstrap/_variables.scss, paste it here and edit the value.
// As example you find one of the most important Bootstrap variable below: $brand-primary.
// Thats the ugly Bootstrap blue used for all the links, primary buttons etc.
// Just add your color code here, compile the theme.scss file to change this color everywhere


$brand-primary: #103dc4;
$brand-secondary: #121a2f;
$brand-tertiary: #f93919;
$brand-grey-dark: #333;
$brand-grey-light: #e4e2e2;

$font-brand-primary: 'Montserrat', sans-serif;

$screen-phone: 400px;
$screen-tablet: 768px;
$screen-desktop: 1024px;

// mixins //
@mixin clear-after(){
	&:after{
		clear: both;
		content: '';
		display: block;
	}
}
@mixin box-shadow(){
	box-shadow: 0px 0px 10px rgba(68,68,68,0.6);
	-webkit-box-shadow: 0px 0px 10px rgba(68,68,68,0.6);
	-moz-box-shadow: 0px 0px 10px rgba(68,68,68,0.6);
}
@mixin h1(){
	font-size: 48px;
	font-weight: 700;
	line-height: 52px;
}
@mixin h2(){
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
}
@mixin b1(){
	font-size: 21px;
	line-height: 30px;
}
@mixin b2(){
	font-size: 16px;
	line-height: 24px;
}
@mixin t1(){
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
}
@mixin t2(){
	font-size: 14px;
	line-height: 18px;
}
@mixin nav1(){
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 20px;
}
@mixin nav2(){
	font-size: 12px;
	line-height: 20px;
}
@mixin cta(){
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
}