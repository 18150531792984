/// GLOBAL ELEMENTS ///
body{
	font-family: $font-brand-primary;
	font-weight: 500;
	@include b2();
	h1{
		@include h1();
		margin-bottom: 20px;
	}
	h2{
		@include h2();
	}
	h4{
		@include t1();
	}
	a{
		color: inherit;
		&:hover, &:focus{
			color: $brand-primary;
			text-decoration: none;
		}
	}
}
.bg-prime{
	background: $brand-primary;
	color: #FFF;
	hr{
		border-color: $brand-secondary;
	}
}
.bg-sec{
	background: $brand-secondary;
	color: #FFF;
	hr{
		border-color: $brand-primary;
	}
}
.sharelinks{
	.fa{
		background: $brand-primary;
		border-radius: 25px;
		color: #FFF;
		font-size: 18px;
		height: 30px;
		line-height: 30px;
		margin: 3px;
		text-align: center;
		width: 30px;
		&:hover{
			background: #FFF;
			color: $brand-primary;
		}
	}
}
#main{
	width: 100%;
}

#wrapper-navbar{
	&.sticky{
		position: fixed;
		width: 100%;
		&.scroll{
			.navbar{
				background: rgba(255,255,255,1) !important;
				height: 100px;
				.container{
					#logo-main{
						background-image: url('../img/logo_blue.png');
						background-size: cover;
						height: 80px;
						width: 80px;
					}
				}
				.navbar-nav{
					li{
						border-color: $brand-grey-dark;
						&.current-menu-item{
							.nav-link{
								color: $brand-primary;
							}
						}
					}
					.nav-link{
						color: $brand-grey-dark;
						&:hover, &:focus{
							color: $brand-primary;
						}
					}
				}
			}
		}
	}
	z-index: 888;
	.navbar{
		&.has_hero{
			background: rgba(255,255,255,0) !important;
			.container{
				#logo-main{
					background: url('../img/logo_white.png') top left no-repeat;
				}
			}
			.navbar-nav{ 
				li{
					@media only screen and (min-width: $screen-phone){
						border-right: 2px solid #FFF;
					}
					.nav-link{
						color: #FFF;
					}
				}
			}
		}
		background: #FFF;
		// background: $brand-primary;
		height: 200px;
		transition: 0.2s;
		.container{
			#logo-main{
				background: url('../img/logo_blue.png') top left no-repeat;
				height: 137px;
				width: 137px;
			}
		}
		.navbar-nav{ 
			li{
				@media only screen and (min-width: $screen-phone){
					border-right: 2px solid $brand-grey-dark;
					&:last-of-type{
						border-right: none;
					}
				}
				&.current-menu-item{
					.nav-link{
						color: $brand-primary;
					}
				}
				.nav-link{
					@include nav1();
					color: $brand-grey-dark;
					padding: 0 15px;
					text-transform: uppercase;
					&:hover, &:focus{
						color: $brand-primary;
					}
					@media only screen and (min-width: $screen-phone){
						line-height: 12px;
					}
				}
			}
		}
	}
}
#page-wrapper, #single-wrapper{
	padding-top: 200px;
	padding-bottom: 0;
	&.has_hero{
		padding-top: 0;
	}
	#page_hero{
		background-size: cover;
		height: 100vh;
		position: relative;
		width: 100%;
		&:before{
			background: rgba(0,0,0,0.2);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		.hero_text{
			bottom: 55px;
			color: #FFF;
			left: 0px;
			position: absolute;
			width: 100%;
		}
		.feature_text{
			bottom: 20%;
			color: #FFF;
			position: absolute;
			right: 50px;
			width: 50%;
			h1{
				margin-bottom: 3px;
			}
			.date{
				@include t1();
				margin-bottom: 20px;
			}
		}
	}
	main{
		section{
			clear: both;
			float: left;
			padding: 150px 0;
			width: 100%;
			&.nopad_section{
				padding: 0;
			}
			.kc_row_inner{
				padding: 60px 0;
			}
			.nopad_inner_row{
				.kc_row_inner{
					padding: 0;
				}	
			}
		}
	}
}
#wrapper-footer-full{
	@include nav2();
	background: $brand-secondary;
	color: #FFF;
	padding: 120px 0 70px;
	@media only screen and (min-width: $screen-tablet){
		.footer-widget{
			border-right: 1px solid #FFF;
			padding: 0 4%;
			&:last-of-type{
				border: none;
			}
		}
	}
	.widget-title{
		@include cta();
		color: $brand-primary;
		text-transform: uppercase;
	}
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}
}
.entry-footer{
	display: none;
}
#wrapper-footer{
	@include nav2();
	background: $brand-secondary;
	color: #FFF;
	padding: 0 0 117px;
	text-align: center;
	.site-info{
		span{
			padding: 0 10px;
		}
	}
}

/// BOOTSTRAP OVERWRITES ///
// .row{
// 	.col{
// 		&:first-of-type{
// 			padding-left: 0;
// 		}
// 		&:last-of-type{
// 			padding-right: 0;
// 		}
// 	}
// }
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
	padding-left: 7px;
	padding-right: 7px;
}

/// THEME AND PLUGIN OVERWRITES ///
section{
	padding: 60px 0;
}
.kc_button, .button, input[type=submit]{
	@include cta();
	background-color: $brand-tertiary !important;
	color: #FFF;
	padding: 15px 22px;
	text-transform: uppercase;
	&:hover, &:focus{
		color: rgba(255,255,255,0.7) !important;
	}
}
.kc-blog-grid, .team_grid{
	margin-left: 0 !important;
	margin-right: 0 !important;
	.post-grid{
		padding: 0 7px !important;
		&:first-of-type{
			padding-left: 0 !important;
		}
		&:last-of-type{
			padding-right: 0 !important;
		}
		.entry-thumb-link{
			&:hover{
				.entry-thumb-wrapper{
					img{
						opacity: 1 !important;
					}
					.entry-thumb-overlay{
						opacity: 1;
					}
				}
			}
			.entry-thumb-wrapper{
				height: 275px;
				overflow: hidden;
				position: relative;
				img{
					height: 100%;
					max-width: none;
					width: auto;
				}
			}
		}
		.content{
			box-shadow: none !important;
			padding: 10px 0 20px !important;
			.post-title-alt{
				margin-bottom: 10px;
				text-transform: none;
			}
			.entry-date{
				@include t1();
				padding-bottom: 25px;
			}
		}
	}
	&.minimalist{
		.post-grid{
			.content{
				padding: 26px 4%;
				.post-title-alt{
					text-align: center;
					&:before, &:after{
						content: '-';
						display: inline-block;
						margin: 0 5px;
					}
				}
				a{
					display: none;
				}
			}
		}
	}
}
.team_grid{
	.row{
		margin: 0;
		width: 100%;
		.image{
			background-repeat: no-repeat;
			background-size: cover;
			height: 275px;
			width: 100%;
		}
		.content{
			.name{
				font-weight: 700;
				text-transform: uppercase;
			}
			.title{
				font-size: 14px;
			}
		}
	}
}
.owl-theme{
	.owl-controls{
		float: left;
		margin-top: 10px !important;
		.owl-page{
			span{
				background: $brand-tertiary !important;
				height: 12px !important;
				margin: 5px 3px !important;
				width: 12px !important;
			}
		}
	}
}
.wpcf7{
	max-width: 1140px;
	padding: 0 15px;
	width: 100%;
	form{
		.row{
			padding: 10px 0;
		}
		h4{
			@include h2();
		}
	}
	input, textarea{
		font-size: 12px !important;
		padding: 22px !important;
		::placeholder{
			color: $brand-grey-light;
			font-weight: 700px;
			text-transform: uppercase;
		}
		&[type=submit]{
			border: none;
			color: #FFF;
			display: inline-block;
			height: auto;
			min-width: 100px;
			padding: 15px !important;
			width: auto;
		}
		&[type=file]{
			background: none;
			border: none;
			cursor: default !important;
			padding: 0 !important;
			&:focus{
				background: none;
			}
		}
	}
	textarea{
		height: 123px;
	}
}
/// CUSTOM ELEMENTS ///
#feature_hero{
	background: url('/wp-content/uploads/2018/10/fpo_project.jpg') top left no-repeat;
	background-size: cover;
	height: 100vh;
	position: relative;
	.hero_nav{
		background: $brand-secondary;
		bottom: 0;
		left: 0;
		margin: 0;
		position: absolute;
		width: 100%;
		.col{
			@include t2();
			color: $brand-primary;
			cursor: pointer;
			padding: 30px;
			transition: 0.2s;
			&:first-of-type{
				padding-left: 10%;
			}
			&:last-of-type{
				padding-right: 10%;
			}
			&:hover, &:focus, &.active{
				background: $brand-primary;
				color: #FFF;
				.num{
					border-bottom: 1px solid #FFF;
				}
			}
			.num{
				@include h2();
				border-bottom: 1px solid $brand-primary;
				margin-bottom: 15px;
			}
			b{
				font-weight: 800;
				text-transform: uppercase;
			}
		}
	}
}
.entry-thumb-overlay{
	background: rgba(16,61,196, 0.8);
	color: #FFF;
	display: table;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	text-align: center;
	top: 0;
	transition: 0.2s;
	width: 100%;
	div{
		@include h2();
		display: table-cell;
		vertical-align: middle;
		span{
			border-bottom: 1px solid #FFF;
			padding-bottom: 17px;
		}
	}
}
.contact_section{
	background: $brand-grey-light;
	.row{
		padding: 25px 0;
	}
}
.careers_component{
	.col{
		a{
			background: #FFF;
			border-left: 4px solid $brand-secondary;
			display: block;
			padding: 30px 15px;
			&:hover, &:focus{
				border-color: $brand-primary;
				.title{
					color: $brand-primary;
				}
				.team, .location, .fa{
					color: $brand-secondary;
				}
			}
		}
		.title{
			display: block;
		}
		.team{
			display: block;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
		.location{
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	h2{
		display: inline-block;
		margin-right: 4px;
	}
	.custom_select{
		display: inline-block;
		padding-right: 25px;
		position: relative;
		&:after{
			color: $brand-tertiary;
			content: "\e604";
			display: block;
			font-family: simple-line-icons;
			font-size: 18px;
			position: absolute;
			right: 0;
			top: 0;
		}
		select{
			appearance: none;
			-webkit-appearance: none; 
	   		-moz-appearance: none;
			background: none;
			border: none;
			color: $brand-primary;
			font-size: 24px;
		}
	}
	#careers_grid{
		margin-top: 65px;
		.row{
			margin: 0;
		}
	}
}
/// PAGE SPECIFIC STYLES ///
.home, .single-project{
	#page-wrapper{
		padding-top: 0;
	}
}
.single-post{
	#wrapper-navbar .navbar{
		@extend .navbar.has_hero;
	}
	article{
		@include clear-after();
	}
	.related_news{
		border-top: 1px solid $brand-grey-light;
		padding: 50px 0;
		.row{
			padding: 20px 15px;
			.col-sm-3{
				text-align: right;
			}
		}
	}
}
.single-project{
	#project_hero{
		overflow: hidden;
		img{
			width: 100%;
		}
	}
	#project_details{
		.row{
			margin: 0;
		}
		.desc{
			padding: 135px 6%;
		}
		.details{
			font-size: 14px;
			padding: 100px 3%;
			hr{
				margin: 15px 0 30px;
			}
		}
	}
	#team_skills{
		.row{
			margin: 0;
		}
		.team, .skills{
			padding: 100px 6%;
		}
		.team{
			.row{
				margin: 15px 0;
			}
			.avatar{
				background-size: cover;
				border-radius: 200px;
				float: left;
				height: 100px;
				overflow: hidden;
				width: 100px;
				img{
					width: 100%;
				}
			}
			.info{
				float: left;
				margin-left: 20px;
				.name{
					display: block;
					font-weight: 700;
					margin-top: 20px;
					text-transform: uppercase;
					&:hover, &:focus{
						color: rgba(255,255,255,0.7) !important;
					}
				}
			}
		}
		.skills{
			ul{
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					margin: 25px 0;
					text-transform: uppercase;
				}
			}
		}
	}
	#quote_block{
		background-size: cover;
		color: #FFF;
		padding: 160px 15%;
		position: relative;
		text-align: center;
		&:before{
			background: rgba(20,26,42,0.5);
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		div{
			position: relative;
		}
		.icon{
			background: url('../img/icon_quote.png') top left no-repeat;
			height: 44px;
			margin: 0 auto 72px;
			width: 61px;
		}
		.quote{
			@include h1();
		}
		.author{
			@include t1();
			margin-top: 25px;
		}
	}
	#related_projects{
		font-size: 18px;
		.image{
			background-repeat: no-repeat;
			background-size: cover;
			height: 277px;
			position: relative;
			width: 100%;
			a{
				&:hover, &:focus{
					.entry-thumb-overlay{
						opacity: 1;
					}
				}
			}
		}
		.title{
			display: block;
			font-weight: 700;
			margin-top: 20px;
			text-transform: uppercase;
		}
	}
}
.single-person{
	#person_details{
		.row{
			margin: 0;
		}
		.avatar{
			background-position: top center;
			background-size: cover;
		}
		.details{
			font-size: 14px;
			padding: 100px 3%;
			hr{
				margin: 15px 0 30px;
			}
			.sharelinks{
				color: $brand-primary;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}
	#quote_block{
		background: $brand-primary;
		color: #FFF;
		padding: 160px 15%;
		position: relative;
		text-align: center;
		.icon{
			background: url('../img/icon_quote.png') top left no-repeat;
			border-bottom: 10px solid #FFF;
			height: 72px;
			margin: 0 auto 72px;
			width: 61px;
		}
		.quote{
			@include h1();
		}
		.author{
			@include t1();
			margin-top: 25px;
		}
	}
}
/*.single-person, .single-career{ // add pages that need to have the white navbar and no hero
	#wrapper-navbar{
		@extend #wrapper-navbar.sticky.scroll;
		.bg-primary{
			height: 200px;
			.container{
				#logo-main{
					height: 137px;
					width: 137px;
				}
			}
		}
	}
}*/
.page-home, .page-news{
	#wrapper-navbar{
		.navbar{
			@extend .navbar.has_hero;
		}
	}
}
.single-career{
	h1{
		@include h2();
		margin-bottom: 0;
	}
	span.details{
		@include t1();
		display: block;
		margin-bottom: 20px;
	}
	#position_details{
		.accordion{
			font-size: 15px;
			padding: 0;
			width: 100%;
			> a{
				border-top: 1px solid $brand-grey-light;
				cursor: pointer;
				display: block;
				padding: 22px 0;
				&:last-of-type{
					border-bottom: 1px solid $brand-grey-light;
				}
				&:after{
					color: $brand-tertiary;
					content: "\e604";
					display: block;
					float: right;
					font-family: simple-line-icons;
					font-weight: 700;
					margin-right: 17px;
				}
				&.ui-state-active{
					&:after{
						content: "\e607";
					}
				}
			}
			div{
				@include b2();
				background: $brand-grey-light;
				border: none;
				color: #000;
				padding: 15px;
			}
		}
	}
}