// Some basic padding for all wrappers
.wrapper {
  padding: $grid-gutter-width 0; }

// Reset hero wrapper padding to 0
#wrapper-hero { padding: 0px !important; }

// Adding basic WordPress classes to pass the WordPress.org tests
.sticky,
.gallery-caption,
.bypostauthor {
  font-size: inherit;
}

// Separate sticky wrapper from main content
.wrapper#wrapper-sticky { border-bottom: 1px solid $gray-300; }

// Adding some contrast background color to footer full widget
#wrapper-footer-full,
#wrapper-static-hero { background-color: $gray-200; }

// Necessary WP classes
.wp-caption { font-size: inherit; }

.wp-caption-text { font-size: inherit; }

.screen-reader-text { @extend .sr-only; }

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter { margin: 0px auto; }

// Post design
.entry-footer span { padding-right: 10px; }

//Woocommerce product gallery slider width fix
figure.woocommerce-product-gallery__wrapper { 
  max-width: inherit !important; 
}

// Limit featured image size to 100%
img.wp-post-image,
article img,
figure,
img,
#secondary img {
  max-width: 100%;
  height: auto;
}

// Skip to content link
a.skip-link {
  position: fixed;
  z-index: 1000;
  top: 0px;
  right: 0px;
}

// Reset Jumbotron default margin
.jumbotron { margin-bottom: 0px; }

// Dropdown translation
.navbar-dark .navbar-nav .dropdown-menu .nav-link{
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color !important;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background: none; // For `<button>`s
  border: 0; // For `<button>`s

  @include hover-focus {
    color: $dropdown-link-hover-color !important;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color !important;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color !important;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }
}

.navbar-light .navbar-brand a {
     color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
}

.navbar-dark .navbar-brand a {
    color: $navbar-dark-active-color;

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
}
